import React, { useMemo, useState, useEffect } from 'react';
import SecurityIssueCard from '../../components/securityissue-card/SecurityIssueCard';
import GroupIssuesTitle from './GroupIssuesTitle';
const CveList = ({ isChecked, filteredCves, collapsedGroups, toggleCollapse, SetSelectedItems, clearSelectedRows, handleActionClick,actions,type }) => {


  const [selectedCves, setSelectedCves] = useState([]);
  const [previousIsChecked, setPreviousIsChecked] = useState(isChecked);
  const handleSelection = (data, isSelected, isGroup = false) => {
    if (isGroup) {
      if (isSelected) {
        setSelectedCves((prev) => [...prev, { groupInfo: data.groupInfo, armory_id: data.armory_id, assets: data.assets }]);
      } else {
        setSelectedCves((prev) => prev.filter((item) => item.groupInfo !== data.groupInfo));
      }
    } else {
      if (isSelected) {
        setSelectedCves((prev) => [...prev, data]);
      } else {
        setSelectedCves((prev) => prev.filter((item) => item.armory_id !== data.armory_id));
      }
    }
  };
  useEffect(() => {
    SetSelectedItems(selectedCves);
  }, [selectedCves, SetSelectedItems]);
  useEffect(() => {
    if (isChecked && !previousIsChecked) {
      setSelectedCves([]);
    }
    setPreviousIsChecked(isChecked);
  }, [isChecked, previousIsChecked]);
  useEffect(() => {
    setSelectedCves([]);
  }, [clearSelectedRows]);

  const renderItems = useMemo(() => {
    return filteredCves.map((item) => {
      const { id, risk_score } = item;
      const cvssRisk =
        risk_score >= 80 && risk_score <= 100
          ? "primary"
          : risk_score >= 40 && risk_score <= 79
            ? "secondary"
            : "tertiary";

      if (isChecked) {
        return (
          <GroupIssuesTitle
            key={id}
            group={item}
            collapsedGroups={collapsedGroups}
            toggleCollapse={toggleCollapse}
            cvssRisk={cvssRisk}
            cvssScore={risk_score}
            actions={actions}
            onSelectionChange={(groupInfo, isSelected) => handleSelection(groupInfo, isSelected, true)}
            clearSelectedRows={clearSelectedRows}
            handleActionClick={handleActionClick}
            type={type}

          />
        );
      } else {
        return (
          <SecurityIssueCard
            key={id}
            classNameVariant={cvssRisk}
            RiskPercentage={risk_score}
            data={item}
            actions={actions}
            onSelectionChange={(data, isSelected) => handleSelection(data, isSelected, false)}
            clearSelectedRows={clearSelectedRows}
            handleActionClick={handleActionClick}
            type={type}
          />
        );
      }
    });
  }, [isChecked, filteredCves, collapsedGroups, toggleCollapse]);

  return <>
    {renderItems}
  </>;
};

export default CveList;
