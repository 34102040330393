import React from "react";

const ActionPopover = ({ rowId, actions = [], onApply }) => {
  if (!actions || actions.length === 0) return <div>No actions available</div>;

  return (
    <div id={`action-${rowId}`} className="custom-action-popover">
      <div className="p-0">
        {actions.map((item) => (
          <div
            key={item?.id}
            onClick={(event) => {
              event.stopPropagation(); 
              onApply(rowId, item); 
            }}
            className="custom-action-items"
          >
            {item?.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActionPopover;
