import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "../../util/axios";
import ScanTerminal from "./ScanTerminal";
import { useParams, useLocation } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb";
import { showToast } from "../../util/toasts";

const Scanner = (props) => {
  const { id } = useParams(); // Destructure to access id directly
  const [target, setTarget] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const path = location.pathname;
  let title = ["Add New Target"];

  if (path.includes("/edit")) {
    title = ["Edit Target"];
  }
  const [scanStatus, setScanStatus] = useState(1);
  const [lastScanUpdate, setlLastScanUpdate] = useState(new Date());
  const fetchEasm = async () => {
    if (!id) {
      setTarget([]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`/targets/${id}`);
      const hostList = [];
      response.data?.hosts_list?.split(",")?.map((el) => {
        hostList.push({
          host: el?.trim(),
        });
      });
      const updatedTargets = {
        scanInfo: {
          ...response.data,
          hosts: hostList,
          id: response.data?.id,
          scan_name: response.data?.title,
          description: response.data?.description,
          scan_target_hosts: response.data?.scan_target_hosts,
        },
      };

      setTarget(updatedTargets);
      setIsLoading(false);
    } catch (error) {
      showToast(
        "Failed to fetch data: " + error.message,
        "error",
        "top-center",
        "light"
      );
      setIsLoading(false);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEasm();
  }, [id]);
  return (
    <React.Fragment>
      <Breadcrumb
        // status={scanStatus}
        lastScanUpdate={lastScanUpdate}
        title={title}
        isScanning={false}
        isLastUpdate={false}
      />
      <Container fluid className="mt-5 px-4">
        <Row>
          <Col className="px-5">
            {!isLoading && <ScanTerminal scan={target} loadMore={fetchEasm} />}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default Scanner;
