import React, { useState, useEffect, useRef, useContext } from "react";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import BarChart from "../../../../components/charts/BarChart";
import axios from "../../../../util/axios";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../../components/card/GenericCard";
import NoData from "../../../../components/empty/NoData.js";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning.js";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";

const Waf = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [noDataDoughnutChart, setNoDataDoughtnutChart] = useState(false);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [wafs, setWafs] = useState([]);
  const [doughnutChartType, setDoughnutChartType] = useState([]);
  const [barChartType, setBarChartType] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const routeParams = useParams();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState([
    {
      eventKey: "1",
      title: "Hostname",
      contentTitle: "Hostname",
      data: [],
    },
    { eventKey: "2", title: "WAF", contentTitle: "WAF", data: [] },
    { eventKey: "3", title: "WAF Name", contentTitle: "WAF Name", data: [] },
    {
      eventKey: "4",
      title: "	WAF Manufacturer",
      contentTitle: "WAF Manufacturer",
      data: [],
    },
  ]);

  const columns = [
    { Header: "Hostname", accessor: "host", isSortable: true },
    {
      Header: "WAF Indication",
      key: "has_waf",
      isSortable: true,
      accessor: (row) => (
        <div
          className={` ${row.has_waf ? "no-pointer" : "cursor-pointer"}`}
          onClick={() => {
            handelClickOnSecurityIssue(row, row.has_waf);
          }}
        >
          <DecisionToggle
            label={row.has_waf ? "Yes" : "No"}
            isIndication={row.has_waf ? true : false}
          />
        </div>
      ),
    },
    { Header: "WAF Name", accessor: "waf_name", isSortable: true },
    { Header: "WAF Manufacturer", accessor: "manufacturer", isSortable: true },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.first_detected
              ? moment(row?.first_detected).format("DD/MM/YY")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.waf_change_status != null ? (
              <CurrentStatus
                status={row?.waf_change_status}
                tooltipInfo={row?.waf_changes}
                headerKeys={{
                  has_waf: "WAF",
                  waf_name: "WAF Name",
                  manufacturer: "WAF Manufacturer",
                  first_detected: "First Detected",
                  change_status: "Current State",
                  waf_change_status: "Current State",
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleRowSelect = (selectedRows) => {};

  const colorList = [
    "#3DDC97",
    "#2AA26E",
    "#A4D4B4",
    "#A7FFD9",
    "#3DDC97",
    "#2AA26E",
    "#A4D4B4",
    "#A7FFD9",
    "#3DDC97",
    "#2AA26E",
    "#A4D4B4",
    "#A7FFD9",
  ];

  const sortedArray = Object.entries(doughnutChartType).sort(
    (a, b) => b[1] - a[1]
  );

  const top7 = sortedArray.slice(0, 7);

  const otherSum = Number(
    sortedArray.slice(7).reduce((acc, item) => acc + item[1], 0)
  );

  const result = {
    ...Object.fromEntries(top7),
  };

  if (otherSum > 0) {
    result["Other"] = otherSum;
  }

  const sortedLabels = ["None"].concat(
    Object.keys(result).filter((label) => label.toLowerCase() !== "none")
  );

  let colorIndex = 0;
  const colors = sortedLabels.map((label) => {
    if (label.toLowerCase() === "none") {
      return "#FF6155";
    } else {
      const color = colorList[colorIndex % colorList.length];
      colorIndex++;
      return color;
    }
  });

  const doughnutChartData = {
    labels: doughnutChartType?.length == 0 ? [] : sortedLabels,
    datasets: [
      {
        label: "WAF Types",
        data:
          doughnutChartType?.length == 0
            ? []
            : sortedLabels.map((key) => result[key]),
        backgroundColor: colors,
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  };

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    onHover: (event, chartElement) => {
      const targetCanvas = event.native ? event.native.target : event.target;
      if (chartElement.length) {
        targetCanvas.style.cursor = "pointer";
      } else {
        targetCanvas.style.cursor = "default";
      }
    },
  };

  const barChartData = {
    labels: ["No WAF", "WAF"],
    datasets: [
      {
        label: "No WAF",
        data: [barChartType.no, barChartType.yes],
        backgroundColor: ["#FF6155", "#3DDC97"],
        borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
        borderWidth: 1,
        maxBarThickness: 70,
        borderRadius: {
          topLeft: 5,
          topRight: 5,
        },
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "white",
          fillStyle: "none",
          padding: 30,
          usePointStyle: true,
          pointStyle: "rectRounded",
          boxSize: 0,
          boxWidth: 0,
          font: {
            family: "Kodchasan",
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        padding: 20,
        yPadding: 90,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },

        yAlign: "bottom",
        xAlign: "center",
        titleFont: {
          family: "Kodchasan",
        },
        bodyFont: {
          family: "Kodchasan",
        },
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "top",
        formatter: (value, context) => {
          return value;
        },
        font: {
          family: "Kodchasan",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
          precision: 0,
          font: {
            family: "Kodchasan",
          },
        },
        border: { dash: [4, 4] },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          borderDash: [10, 10],
        },
      },
      x: {
        ticks: {
          color: "white",
          font: {
            family: "Kodchasan",
          },
        },
        display: true,
        grid: {
          color: "transparent",
          drawOnChartArea: true,
          borderDash: [10, 10],
          borderDashOffset: function (context) {
            return context.index === context.chart.data.labels.length ? 0 : 10;
          },
        },
        border: {
          color: "#959595",
          width: 1,
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        bottom: -40,
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
  };

  const wafsFilterOptions = [
    {
      id: 0,
      name: "Yes",
      type: "WAF: Yes",
      key: "3",
      active: false,
    },
    {
      id: 1,
      name: "No",
      type: "WAF: No",
      key: "3",
      active: false,
    },
  ];

  const updateTabsWithData = (wafData) => {
    const manufacturers = Array.from(
      new Set(wafData.map((waf) => waf.manufacturer))
    ).map((manufacturer, index) => ({
      id: index,
      name: manufacturer ? manufacturer : "-",
      type: manufacturer
        ? "WAF Manufacturer: " + manufacturer
        : "WAF Manufacturer: -",

      key: "4",
      active: false,
    }));
    const wafNames = Array.from(
      new Set(wafData.map((waf) => waf.waf_name))
    ).map((name, index) => ({
      id: index,
      name: name ? name : "-",
      type: name ? "WAF Name: " + name : "WAF Name: -",
      key: "2",
      active: false,
    }));
    const websites = Array.from(new Set(wafData.map((waf) => waf.host))).map(
      (host, index) => ({
        id: index,
        name: host,
        type: "Hostname: " + host,
        key: "1",
        active: false,
      })
    );

    setTabs([
      {
        eventKey: "1",
        title: "Hostname",
        contentTitle: "Hostname",
        data: websites,
      },

      {
        eventKey: "3",
        title: "WAF Indication",
        contentTitle: "WAF Indication",
        data: wafsFilterOptions,
      },
      {
        eventKey: "2",
        title: "WAF Name",
        contentTitle: "WAF Name",
        data: Array.from(wafNames),
      },
      {
        eventKey: "4",
        title: "WAF Manufacturer",
        contentTitle: "WAF Manufacturer",
        data: Array.from(manufacturers),
      },
    ]);
  };

  const fetchEasm = async () => {
    try {
      const { data } = await axios.get(`scans/${routeParams.target_id}/waf`);
      const filteredData = data.scan.scan_hosts;
      const modifiedData = filteredData?.map((el) => {
        return {
          ...el,
          waf_name: el.waf_name && el.waf_name !== "None" ? el.waf_name : "-",
          manufacturer:
            el.manufacturer && el.manufacturer !== "None"
              ? el.manufacturer
              : "-",
          host: el.host?.host,
          has_waf: el?.has_waf ? true : false,
        };
      });

      setWafs(modifiedData);
      setDoughnutChartType(data.groups?.type);
      setWebUpdatesData(data?.groups?.changes);
      setBarChartType(data.groups?.waf);
      if (
        scanningStatus === -1 ||
        scanningStatus === 3 ||
        scanningStatus === 4 ||
        scanningStatus === 1 ||
        scanningStatus === 2
      ) {
        if (
          Object.values(data?.groups?.type)?.every((value) => value === 0) ||
          Object.values(data?.groups?.type)?.length === 0
        ) {
          setNoDataDoughtnutChart(true);
        } else {
          setNoDataDoughtnutChart(false);
        }
        if (
          Object.values(data?.groups?.waf)?.every((value) => value === 0) ||
          Object.values(data?.groups?.waf)?.length === 0
        ) {
          setNoDataBarChart(true);
        } else {
          setNoDataBarChart(false);
        }
      }
      setIsLoading(false);
      updateTabsWithData(modifiedData);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanningStatus === 3 || scanningStatus === -1 || scanningStatus === 4) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchEasm();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchEasm();
        fetchIntervalRef.current = setInterval(fetchEasm, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredWafs = wafs.filter((waf) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "4") {
          return (
            (filter.name === "-" &&
              (!waf.manufacturer || waf.manufacturer === "-")) ||
            (waf.manufacturer &&
              waf.manufacturer.toLowerCase() === lowerCaseFilter)
          );
        } else if (eventKey === "2") {
          return (
            (filter.waf_name === "-" &&
              (!waf.waf_name || waf.waf_name === "-")) ||
            (waf.waf_name && waf.waf_name.toLowerCase() === lowerCaseFilter)
          );
        } else if (eventKey === "3") {
          return (
            (lowerCaseFilter === "no" && !waf.has_waf) ||
            (lowerCaseFilter === "yes" && waf.has_waf === true)
          );
        } else if (eventKey === "1" && waf.host) {
          return waf.host.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "6") {
          return (
            waf?.waf_change_status === statusMap[filter.name.toLowerCase()]
          );
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (waf.manufacturer &&
        waf.manufacturer.toLowerCase().includes(searchValue.toLowerCase())) ||
      (waf.waf_name &&
        waf.waf_name.toLowerCase().includes(searchValue.toLowerCase())) ||
      (waf.host && waf.host.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });
  const handleChartClick = (name) => {
    const finalName = name === "None" ? "-" : name;
    const updatedFilters = webUpdatesFilter(
      finalName,
      activeFilters,
      "2",
      "WAF Name"
    );
    const updatedWafNames = tabs[2].data.map((waf) => ({
      ...waf,
      active: updatedFilters.some((filter) => filter.name === waf.name),
    }));
    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[2] = {
        ...newTabs[2],
        data: updatedWafNames,
      };
      return newTabs;
    });
    setActiveFilters(updatedFilters);
  };

  const handleBarChartClick = (value) => {
    setActiveFilters(
      webUpdatesFilter(value, activeFilters, "3", "WAF Indication")
    );
  };
  const handelClickOnSecurityIssue = (row, hafsWaf) => {
    if (!hafsWaf) {
      navigate(
        `/security-issues/${routeParams?.target_id}?host=${row?.host}&armoryIdParam=arm-waf1`
      );
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row waf-main web-updates-container">
            <div className="col-12  col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 waf-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      isDatainProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing && noDataDoughnutChart ? (
                        <NoData />
                      ) : noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : (
                        <DoughnutChart
                          data={doughnutChartData}
                          options={doughnutChartOptions}
                          onHandleClick={handleChartClick}
                        />
                      )
                    }
                    title={"WAF Types"}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <WebUpdate
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDatainProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7 h-100">
                      <GenericCard
                        children={
                          isDatainProcess ? (
                            <NoData />
                          ) : noDataBarChart && isDataisPartiallyProcessing ? (
                            <NoData />
                          ) : noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : (
                            <BarChart
                              isChartLabelsOnTop
                              barData={barChartData}
                              optionsData={barChartOptions}
                              onBarClick={handleBarChartClick}
                              dynamicLabels={["No", "Yes"]}
                              isData={
                                !!(
                                  barChartType &&
                                  barChartType.no !== undefined &&
                                  barChartType.yes !== undefined
                                )
                              }
                            />
                          )
                        }
                        title={"WAF Indication"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Waf Scanner"} />
            </div>
          </div>

          <div className=" pb-5">
            {/* <div className="table-container">
              <div className="table-left">Results</div>
              <div>
                <BaseInput
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  icon={Search}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div>
              <BaseFilter
                tabs={tabs}
                onFilterChangeWaf={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                iskeyfilter={true}
                className="mb-3"
                totalRecords={filteredWafs.length}
                exportHeader={[
                  "Hostname",
                  "WAF",
                  "WAF Name",
                  "WAF Manufacturer",
                ]}
                exportTitle={`WAF_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportRows={filteredWafs.map((waf) => [
                  waf.host,
                  waf.has_waf ? "Yes" : "No",
                  waf.waf_name,
                  waf.manufacturer,
                ])}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredWafs}
                selectable={true}
                onRowSelect={handleRowSelect}
                showCheckboxes={false}
                action={false}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Waf;
