import React, { useState, useEffect, useRef, useContext } from "react";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import { useParams, useOutletContext } from "react-router-dom";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import axios from "../../../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../../components/card/GenericCard";
import NoData from "../../../../components/empty/NoData";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning.js";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import WebUpdatesHorizontal from "../../../../components/website-updates/WebUpdatesHorizontal.js";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";

const HostingProvider = () => {
  const { scanningStatus } = useOutletContext();
  const [searchValue, setSearchValue] = useState("");
  const { dropdownItems } = useContext(ScannerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [noDataDoughnutChart, setNoDataDoughtnutChart] = useState(false);
  const [isDataInProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [doughnutChartType, setDoughnutChartType] = useState([]);
  const [hosting, setHosting] = useState([]);
  const routeParams = useParams();
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [tabs, setTabs] = useState([
    {
      eventKey: "1",
      title: "IP Address",
      contentTitle: "IP Address",
      data: [],
    },
    {
      eventKey: "3",
      title: "Hosting Provider",
      contentTitle: "Hosting Provider",
      data: [],
    },
    {
      eventKey: "2",
      title: "Hosting Provider Name",
      contentTitle: "Hosting Provider Name",
      data: [],
    },
  ]);

  const [pieChartDataType, setPieChartDataType] = useState({
    labels: [],
    responsive: true,
    maintainAspectRatio: false,
    datasets: [
      {
        data: [],
        backgroundColor: ["#7CB9E8", "#00308F", "#0066b2"],
        borderColor: ["rgba(255, 99, 132, 0)"],
        borderWidth: 1,
      },
    ],
  });

  const colorList = [
    "#398FFE",
    "#41E7DF",
    "#B694FF",
    "#238D88",
    "#1D56C9",
    "#727EF7",
    "#8067DC",
    "#39B9FE",
    "#33BAB3",
    "#38CEC7",
    "#81D3FF",
    "#D6C2FF",
    "#41E7DF",
    "#398FFE",
    "#B694FF",
  ];

  const sortedArray = Object.entries(doughnutChartType).sort(
    (a, b) => b[1] - a[1]
  );

  const top7 = sortedArray.slice(0, 7);

  const otherSum = Number(
    sortedArray.slice(7).reduce((acc, item) => acc + item[1], 0)
  );

  const result = {
    ...Object.fromEntries(top7),
  };

  if (otherSum > 0) {
    result["Other"] = otherSum;
  }

  const sortedLabels = Object.keys(result).filter(
    (label) => label.toLowerCase() !== "none"
  );

  if (result["None"]) {
    sortedLabels.unshift("None");
  }

  let colorIndex = 0;
  const colors = sortedLabels.map((label) => {
    if (label.toLowerCase() === "none") {
      return "#F5F5F5";
    } else {
      const color = colorList[colorIndex % colorList.length];
      colorIndex++;
      return color;
    }
  });

  const doughnutChartData = {
    labels: doughnutChartType?.length == 0 ? [] : sortedLabels,
    datasets: [
      {
        label: "",
        data:
          doughnutChartType?.length == 0
            ? []
            : sortedLabels.map((key) => result[key]),
        backgroundColor: colors,
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  };

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    onHover: (event, chartElement) => {
      const targetCanvas = event.native ? event.native.target : event.target;
      if (chartElement.length) {
        targetCanvas.style.cursor = "pointer";
      } else {
        targetCanvas.style.cursor = "default";
      }
    },
  };

  const columns = [
    { Header: "IP Address", accessor: "ip", isSortable: true },
    {
      Header: "Hosting Provider",
      key: "has_hosting",
      accessor: (row) => {
        return <span>{row?.hosting_provider ? "Yes" : "No"}</span>;
      },
      isSortable: true,
    },
    {
      Header: "Hosting Provider Name",
      accessor: "hosting_provider",
      isSortable: true,
    },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.first_detected
              ? moment(row?.first_detected).format("DD/MM/YY")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.hosting_change_status != null ? (
              <CurrentStatus
                status={row?.hosting_change_status}
                tooltipInfo={row?.hosting_changes}
                headerKeys={{
                  hosting_provider: "Hosting Provider Name",
                  ip: "IP Address",
                  first_detected: "First Detected",
                  change_status: "Current State",
                  hosting_change_status: "Current State",
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const getColor = (index) => {
    const colors = [
      "#3DDC97",
      "#FFCF9C",
      "#6B5B95",
      "#88B04B",
      "#92A8D1",
      "#955251",
      "#2AA16E",
      "#55B4B0",
      "#009B77",
      "#DD4124",
      "#68B0AB",
      "#D65076",
      "#FF6F61",
      "#45B8AC",
      "#EFC050",
      "#F7CAC9",
      "#5B5EA6",
      "#9B2335",
      "#B565A7",
      "#DFCFBE",
      "#E15D44",
      "#A7FFD9",
    ];
    return colors[index % colors.length];
  };

  const hostingFilterOptions = [
    {
      id: 0,
      name: "Yes",
      type: "Hosting Provider: Yes",
      key: "3",
      active: false,
    },
    {
      id: 1,
      name: "No",
      type: "Hosting Provider: No",
      key: "3",
      active: false,
    },
  ];

  const updateTabsWithData = (hostings) => {
    const ips = Array.from(new Set(hostings.map((host) => host.ip))).map(
      (ip, index) => ({
        id: index,
        name: ip?.ip,
        type: "IP Address: " + ip?.ip,
        key: "1",
        active: false,
      })
    );

    const hostingName = Array.from(
      new Set(hostings.map((host) => host.hosting_provider))
    )
      ?.filter((name) => name)
      .map((ip, index) => ({
        id: index,
        name: ip,
        type: "Hosting Provider Name: " + ip,
        key: "2",
        active: false,
      }));

    setTabs([
      {
        eventKey: "1",
        title: "IP Address",
        contentTitle: "IP Address",
        data: Array.from(ips),
      },
      {
        eventKey: "3",
        title: "Hosting Provider",
        contentTitle: "Hosting Provider",
        data: Array.from(hostingFilterOptions),
      },
      {
        eventKey: "2",
        title: "Hosting Provider Name",
        contentTitle: "Hosting Provider Name",
        data: Array.from(hostingName),
      },
    ]);
  };

  const fetchHosting = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams?.target_id}/hosting`
      );
      const entities = Object.entries(data?.groups?.hosting);
      setDoughnutChartType(data.groups?.hosting);
      setWebUpdatesData(data?.groups?.changes);
      if (
        scanningStatus === -1 ||
        scanningStatus === 3 ||
        scanningStatus === 4 ||
        scanningStatus === 1 ||
        scanningStatus === 2
      ) {
        if (
          Object.values(data?.groups?.hosting)?.length === 0 ||
          Object.values(data?.groups?.hosting)?.every((value) => value === 0)
        ) {
          setNoDataDoughtnutChart(true);
        } else {
          setNoDataDoughtnutChart(false);
        }
      }
      setPieChartDataType({
        labels: entities?.map((el) => el[0]),
        datasets: [
          {
            data: entities?.map((el) => el[1]),
            backgroundColor: entities.map((_, index) => getColor(index)),
            size: 24,
            borderWidth: 1,
          },
        ],
      });
      const filteredData = data?.items || [];
      if (
        data?.items?.length == 0 &&
        (scanningStatus === 2 || scanningStatus === 1)
      ) {
        setIsDataInProcess(true);
      } else {
        setIsDataInProcess(false);
      }
      setHosting(
        filteredData?.map((el) => ({
          ...el,
          ip: el.ip.ip,
          has_hosting: el.hosting_provider ? true : false,
        }))
      );
      updateTabsWithData(filteredData);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setIsDataInProcess(true);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanningStatus === 3 || scanningStatus === -1 || scanningStatus === 4) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchHosting();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchHosting();
        fetchIntervalRef.current = setInterval(fetchHosting, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };
  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredHosting = hosting.filter((host) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return host.ip && host.ip.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return (
            host.hosting_provider &&
            host.hosting_provider.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "3") {
          return (
            (lowerCaseFilter === "no" && !host.hosting_provider) ||
            (lowerCaseFilter === "yes" && host.hosting_provider)
          );
        } else if (eventKey === "6") {
          return (
            host?.hosting_change_status === statusMap[filter.name.toLowerCase()]
          );
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (host.hosting_provider &&
        host.hosting_provider
          .toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (host.ip && host.ip.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });
  const handleChartClick = (name) => {
    const updatedFilters = webUpdatesFilter(
      name,
      activeFilters,
      "2",
      "Hosting Provider Name"
    );
    const updatedHostingName = tabs[2].data.map((host) => ({
      ...host,
      active: updatedFilters.some((filter) => filter.name === host.name),
    }));

    // Update the tabs state
    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[2] = {
        ...newTabs[2],
        data: updatedHostingName,
      };
      return newTabs;
    });
    setActiveFilters(updatedFilters);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      isDataInProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing && noDataDoughnutChart ? (
                        <NoData />
                      ) : noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : (
                        <DoughnutChart
                          data={doughnutChartData}
                          options={doughnutChartOptions}
                          onHandleClick={handleChartClick}
                        />
                      )
                    }
                    title={"Hosting Providers"}
                    isCentered={false}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-12">
                      <GenericCard
                        children={
                          <WebUpdatesHorizontal
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDataInProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Hosting Provider Scanner"} />
            </div>
          </div>
          <div className=" pb-5">
            {/* <div className="table-container">
              <div className="table-left">Results</div>
              <div>
                <BaseInput
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  icon={Search}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div>
              <BaseFilter
                tabs={tabs}
                className="mb-3"
                totalRecords={filteredHosting.length}
                onFilterChangeWaf={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                iskeyfilter={true}
                exportTitle={`HostingProvider_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportHeader={[
                  "IP Address",
                  "Hosting Provider",
                  "Hosting Provider Name",
                ]}
                exportRows={filteredHosting.map((host) => [
                  host.ip,
                  host.has_hosting ? "Yes" : "No",
                  `"${host.hosting_provider}"`,
                ])}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredHosting}
                selectable={true}
                showCheckboxes={false}
                action={false}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default HostingProvider;
