// ExpiredColumn.js
import React from "react";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import { useParams, useNavigate } from "react-router-dom";
const ExpiredColumn = ({ data }) => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const handleExpiredClick = () => {
    if (data.is_expired) {
      navigate(
        `/security-issues/${routeParams?.target_id}?host=${data?.host}&armoryIdParam=arm-cert1`
      );
    }
  };
  return (
    <div
      onClick={handleExpiredClick}
      className={` ${!data?.is_expired ? "no-pointer" : "cursor-pointer"}`}
    >
      <DecisionToggle
        label={!data.is_expired ? "No" : "Yes"}
        isIndication={!data.is_expired ? true : false}
      />
    </div>
  );
};
export default ExpiredColumn;
