import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
const CertificateNumberReduction = ({
  data,
  isNumbers = false,
  isUnsecuredCiphers = false,
  isSecureProtocolsLength = false,
}) => {
  const navigate = useNavigate();
  const routeParams = useParams();

  const handelClickOnDaysLeft = () => {
    if (data.days < 0) {
      // Use host when it exists
      if (data?.host) {
        navigate(
          `/security-issues/${routeParams?.target_id}?host=${data?.host}&armoryIdParam=arm-cert1`
        );
      } else if (data?.ip) {
        // Use ip when host is not available
        navigate(
          `/security-issues/${routeParams?.target_id}?ip=${data?.ip}&armoryIdParam=arm-cert1`
        );
      }
    } else if (data.days > 0 && data.days <= 30) {
      console.log("data-data", data);

      // Conditional handling of host or ip
      if (data?.host) {
        navigate(
          `/security-issues/${routeParams?.target_id}?host=${data?.host}&armoryIdParam=arm-cert2`
        );
      } else if (data?.ip) {
        navigate(
          `/security-issues/${routeParams?.target_id}?ip=${data?.ip}&armoryIdParam=arm-cert2`
        );
      }
    }
  };

  const handelClickOnCiphers = () => {
    if (insecureCiphersLength) {
      // Use host when available, otherwise use ip
      if (data?.host) {
        navigate(
          `/security-issues/${routeParams?.target_id}?host=${data?.host}&armoryIdParam=arm-cert4`
        );
      } else if (data?.ip) {
        navigate(
          `/security-issues/${routeParams?.target_id}?ip=${data?.ip}&armoryIdParam=arm-cert4`
        );
      }
    }
  };

  const handelClickOnProtocol = () => {
    if (insecureCiphersLength) {
      // Use host when available, otherwise use ip
      if (data?.host) {
        navigate(
          `/security-issues/${routeParams?.target_id}?host=${data?.host}&armoryIdParam=arm-cert3`
        );
      } else if (data?.ip) {
        navigate(
          `/security-issues/${routeParams?.target_id}?ip=${data?.ip}&armoryIdParam=arm-cert3`
        );
      }
    }
  };

  const secureCiphersLength = data?.ciphers?.secure?.length || 0;
  const insecureCiphersLength = data?.ciphers?.insecure?.length || 0;
  const secureProtocolsLength = data?.protocols?.secure?.length || 0;
  const insecureProtocolsLength = data?.protocols?.insecure?.length || 0;
  return (
    <>
      {isNumbers && (
        <div
          className={`${
            data.days !== null && data.days <= 30 ? "expired" : ""
          }`}
          onClick={
            data.days < 0 || (data.days > 0 && data.days <= 30)
              ? handelClickOnDaysLeft
              : undefined
          }
        >
          {data.days !== null ? data.days : "-"}
        </div>
      )}

      {isUnsecuredCiphers && (
        <div>
          <div>
            {secureCiphersLength > 0 ? (
              <OverlayTrigger
                placement="top"
                className="custom-tooltip"
                overlay={
                  <Popover
                    id={`tooltip-${data.id}`}
                    className="custom-popover-arrow"
                  >
                    <Popover.Body className="comming-tool-body">
                      <label className="comming-text-tool">
                        {data?.ciphers?.secure?.map((el, index) => (
                          <div key={el}>
                            {el}
                            {index === secureCiphersLength - 1 ? "" : ","}
                          </div>
                        ))}
                      </label>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="ciphers-cell">
                  Secured Ciphers:{" "}
                  <span className={secureCiphersLength > 0 ? "greentext" : ""}>
                    {secureCiphersLength}
                  </span>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="ciphers-cell">
                Secured Ciphers: <span>0</span>
              </div>
            )}
          </div>
          <div>
            {insecureCiphersLength > 0 ? (
              <OverlayTrigger
                placement="top"
                className="custom-tooltip"
                overlay={
                  <Popover
                    id={`tooltip-${data.id}`}
                    className="custom-popover-arrow"
                  >
                    <Popover.Body className="comming-tool-body">
                      <label className="comming-text-tool">
                        {data?.ciphers?.insecure?.map((el, index) => (
                          <div key={el}>
                            {el}
                            {index === insecureCiphersLength - 1 ? "" : ","}
                          </div>
                        ))}
                      </label>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="ciphers-cell ">
                  Unsecured Ciphers:{" "}
                  <span
                    onClick={handelClickOnCiphers}
                    className={
                      insecureCiphersLength > 0 ? "redtext cursor-pointer" : ""
                    }
                  >
                    {insecureCiphersLength}
                  </span>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="ciphers-cell">
                Unsecured Ciphers: <span>0</span>
              </div>
            )}
          </div>
        </div>
      )}

      {isSecureProtocolsLength && (
        <div>
          <div>
            {secureProtocolsLength > 0 ? (
              <OverlayTrigger
                placement="top"
                className="custom-tooltip"
                overlay={
                  <Popover
                    id={`tooltip-${data.id}`}
                    className="custom-popover-arrow"
                  >
                    <Popover.Body className="comming-tool-body">
                      <label className="comming-text-tool">
                        {data?.protocols?.secure?.map((el, index) => (
                          <div key={el}>
                            {el}
                            {index === secureProtocolsLength - 1 ? "" : ","}
                          </div>
                        ))}
                      </label>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="ciphers-cell">
                  Secured Protocols:{" "}
                  <span
                    className={secureProtocolsLength > 0 ? "greentext" : ""}
                  >
                    {secureProtocolsLength}
                  </span>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="ciphers-cell">
                Secured Protocols: <span>0</span>
              </div>
            )}
          </div>
          <div>
            {insecureProtocolsLength > 0 ? (
              <OverlayTrigger
                placement="top"
                className="custom-tooltip"
                overlay={
                  <Popover
                    id={`tooltip-${data.id}`}
                    className="custom-popover-arrow"
                  >
                    <Popover.Body className="comming-tool-body">
                      <label className="comming-text-tool">
                        {data?.protocols?.insecure?.map((el, index) => (
                          <div key={el}>
                            {el}
                            {index === insecureProtocolsLength - 1 ? "" : ","}
                          </div>
                        ))}
                      </label>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="ciphers-cell">
                  Unsecured Protocols:{" "}
                  <span
                    onClick={handelClickOnProtocol}
                    className={
                      insecureProtocolsLength > 0
                        ? "redtext cursor-pointer"
                        : ""
                    }
                  >
                    {insecureProtocolsLength}
                  </span>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="ciphers-cell">
                Unsecured Protocols: <span>0</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CertificateNumberReduction;
