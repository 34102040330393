import React, { useRef, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../util/axios";
import DomainList from "./DomainListHeader";
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import LogoAnim from "../../assets/animation/Animation-logo.mp4";
import AssetsScanStats from "./AnimatedAssetsStats";
import LogsOutput from "../../components/web-scanner/PointerScanLogs";
import StopScannerConfirmation from "./StopScannerConfirmationModal";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { showToast } from "../../util/toasts";

const ScansList = ({ item, loadMore }) => {
  const { id } = useParams();
  const location = useLocation();
  const breadcrumbItems = ["Asset Inventory"];
  const [isScanStarted, setIsScanStarted] = useState(0);
  const [scanStatus, setScanStatus] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [scanningData, setScanningData] = useState([]);
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalActive, setTotalActive] = useState(0);
  const [totalActiveHost, setTotalActiveHost] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef(null);
  const [isActiveLink, setIsActiveLink] = useState("Overview");

  const handleStatsFetch = (data) => {
    setStats(data);
  };
  const updateScanningData = (scanData) => {
    setScanningData([]);
    let logsData = [];
    if (scanData?.logs) {
      logsData = scanData.logs.map((log) => {
        return {
          type: moment(log.updated_at).isValid()
            ? moment(log.updated_at).format("YYYY-MM-DD HH:mm:ss")
            : "Invalid date",
          message: log.log,
          isTimestamp: log.hide_timestamp,
          isDivider: log.has_divider,
        };
      });
    }
    if (scanningData.length < logsData.length) {
    }
    setScanningData(logsData);
  };

  const handelWebScanner = (data) => {
    if (data?.logs) {
      updateScanningData(data);
    }
    if (data?.target) {
      setLastUpdated(data?.target?.updated_at);
    } else if (
      data?.scanInfo?.services[0]?.service_status == -1 ||
      data?.scanInfo?.services[0]?.service_status == 3
    ) {
      setLastUpdated(data?.scanInfo?.services[0]?.service_end_time);
    } else {
      setLastUpdated(data?.scanInfo?.services[0]?.updated_at);
    }
  };

  const servicesStatusCheck = (status) => {
    setScanStatus(status);
  };
  const handleScannerStop = () => {
    setShowModal(true);
  };

  const confirmScannerStop = () => {
    scannerStop();
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const scannerStop = () => {
    axios
      .post(`/scans/${id}/stop`)
      .then((response) => {
        showToast(response.data.message, "success", "top-center", "info");
        window.location.reload();
      })
      .catch((e) => {
        showToast(
          "Failed to stop the Scan!",
          "error",
          "top-center",
          "light"
        );
      });
  };

  useEffect(() => {
    const video = videoRef.current;
    const handleTimeUpdate = () => {
      if (video && video.currentTime >= video.duration - 1) {
        video.pause();
      }
    };

    if (video) {
      video.play().catch((error) => {
        console.log(error);
      });

      video.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (video) {
        video.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!isLoading && video) {
      video.currentTime = 0;
      video.play().catch((error) => {
        console.log(error);
      });
    }
  }, [isLoading]);

  useEffect(() => {
    location?.search?.includes("tab=1")
      ? setIsActiveLink("Assets Discovery")
      : setIsActiveLink("Overview");
  }, [id]);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      )}
      <div style={{ display: isLoading ? "none" : "block" }}>
        <Breadcrumb
          breadcrumbItems={breadcrumbItems}
          status={scanStatus}
          endTime={lastUpdated}
          isStopScan={true}
          scannerStop={handleScannerStop}
        />
        <Navbar className="border-bottom mt-4">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto ms-3">
              <Link
                className={`disable-link-color discovery-nav-text text-white ${isActiveLink === "Overview" ? "discovery-active-nav-text" : ""
                  }`}
                onClick={() => {
                  setIsActiveLink("Overview");
                }}
              >
                Overview
              </Link>
              <Link
                className={`disable-link-color discovery-nav-text text-white mx-4 ${isActiveLink === "Assets Discovery"
                    ? "discovery-active-nav-text"
                    : ""
                  }`}
                onClick={() => {
                  setIsActiveLink("Assets Discovery");
                }}
              >
                Asset Discovery
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="py-5 px-4">
          <>
            {isActiveLink === "Overview" && (
              <div className="animation-section">
                <div className="video-container">
                  <video
                    ref={videoRef}
                    src={LogoAnim}
                    width="400px"
                    height="400px"
                    loop
                    muted
                  />
                </div>
                <div className="logs-output">
                  <LogsOutput data={scanningData} isType={false} />
                </div>
                {!isLoading && <AssetsScanStats stats={stats} />}
              </div>
            )}
            <DomainList
              setScanStatus={servicesStatusCheck}
              webScanner={handelWebScanner}
              onStatsFetch={handleStatsFetch}
              setIsScanStarted={setIsScanStarted}
              setIsLoading={setIsLoading}
              setTotalActive={setTotalActive}
              setTotalActiveHost={setTotalActiveHost}
              isActiveLink={isActiveLink}
            />

            <StopScannerConfirmation
              show={showModal}
              onConfirm={confirmScannerStop}
              onCancel={handleCloseModal}
            />
          </>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScansList;
