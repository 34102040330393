import { toast } from "react-toastify";

export const showToast = (
  message,
  type,
  position = "top-center",
  theme = "light",
  options = {}
) => {
  const toastId = toast[type](message, {
    position: position,
    theme: theme,
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  });

  return toastId;
};

export const showUpdateToast = (
  toasterId,
  render,
  type,
  isLoading,
  options = {}
) => {
  toast.update(toasterId, {
    render,
    type,
    isLoading,
    ...options,
  });
}

export const showPromiseToast = (
  data,
  render,
  options = {}
) => {
  toast.promise(
    data,
    render,
    options = {}
  );
}