import React, { useState, useEffect, useRef, useContext } from "react";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import BaseInput from "../../../../components/form/BaseInput";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import { ReactComponent as Search } from "../../../../assets/images/search.svg";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import GenericCard from "../../../../components/card/GenericCard";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import axios from "../../../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import DecisionToggle from "../../../../components/badge/DecisionToggle.js";
import NoData from "../../../../components/empty/NoData.js";
import { Badge } from "react-bootstrap";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning.js";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import WebUpdatesHorizontal from "../../../../components/website-updates/WebUpdatesHorizontal.js";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import { dnsFilterOption } from "./filterOptions.js";

const Dns = () => {
  const { scanningStatus } = useOutletContext();
  const { dropdownItems } = useContext(ScannerContext);

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [noDataDoughnutChart, setNoDataDoughtnutChart] = useState(false);
  const [dns, setDns] = useState([]);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const routeParams = useParams();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState([
    {
      eventKey: "1",
      title: "Domain Name",
      contentTitle: "Domain Name",
      data: [],
    },
    { eventKey: "2", title: "Registrar", contentTitle: "Registrar", data: [] },
    {
      eventKey: "3",
      title: "Name Servers",
      contentTitle: "Name Servers",
      data: [],
    },
  ]);
  const [doughnutChartData, setDoughnutChartData] = useState({
    labels: [],
    responsive: true,
    maintainAspectRatio: false,
    datasets: [
      {
        data: [],
        backgroundColor: ["#ff6155", "#2AA16E"],
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
        borderAlign: "center",
      },
    ],
  });

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const fetchDns = async () => {
    try {
      const { data } = await axios.get(`scans/${routeParams?.target_id}/dns`);
      // if (
      //   data?.domains?.length == 0 &&
      //   (scanningStatus === 2 || scanningStatus === 1)
      // ) {
      //   setIsDataInProcess(true);
      // } else {
      //   setIsDataInProcess(false);
      // }
      setWebUpdatesData(data?.groups?.changes);

      setDoughnutChartData({
        labels: ["DNSSEC Signed", "DNSSEC Unsigned"],
        datasets: [
          {
            data: [data?.groups?.secure?.yes, data?.groups?.secure?.no],
            backgroundColor: ["#2AA16E", "#ff6155"],
            hoverOffset: 4,
            borderColor: "transparent",
            borderWidth: 0,
          },
        ],
      });
      if (
        scanningStatus === -1 ||
        scanningStatus === 3 ||
        scanningStatus === 4 ||
        scanningStatus === 1 ||
        scanningStatus === 2
      ) {
        if (data?.groups?.secure?.yes || data?.groups?.secure?.no) {
          setNoDataDoughtnutChart(false);
        } else {
          setNoDataDoughtnutChart(true);
        }
      }

      let modifiedData = data?.domains?.map((el) => {
        let dnssecStatus = el.domainInfo?.dnssec;
        if (dnssecStatus === "signedDelegation" || dnssecStatus === "Enabled") {
          dnssecStatus = "Signed";
        } else if (dnssecStatus === "Disabled") {
          dnssecStatus = "Unsigned";
        }

        const sortableDnssec = dnssecStatus ? dnssecStatus.toLowerCase() : "";
        const sortableNameServers =
          el.domainInfo?.nameservers
            ?.map((ns) => ns.toLowerCase())
            .join(", ") || "";

        return {
          ...el,
          registrar: el.domainInfo?.registrar || "-",
          registration_date: el.domainInfo?.registration_date
            ? new Date(el.domainInfo?.registration_date)?.toLocaleDateString()
            : "-",
          validity: el.domainInfo?.validity
            ? new Date(el.domainInfo?.validity)?.toLocaleDateString()
            : "-",
          dnssec: dnssecStatus,
          nameservers: el.domainInfo?.nameservers?.map((ns) =>
            ns.toLowerCase()
          ), // Ensure name servers are lowercase
          sortableDnssec,
          sortableNameServers,
          change_status: el?.domainInfo?.dns_change_status,
          changes: el?.domainInfo?.dns_changes,
          first_detected: el?.domainInfo?.first_detected,
        };
      });

      updateTabsWithData(modifiedData);
      setDns(modifiedData);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  const SecurityIssue = (domain) => {
    navigate(
      `/security-issues/${routeParams?.target_id}?domain=${domain}&armoryIdParam=arm-dnssec1`
    );
  };

  const columns = [
    { Header: "Domain Name", accessor: "host", isSortable: true },
    { Header: "Registrar", accessor: "registrar", isSortable: true },
    {
      Header: "Registered On",
      accessor: "registration_date",
      isSortable: true,
    },
    {
      Header: "Expires On",
      accessor: "validity",
      isSortable: true,
    },
    {
      Header: "DNSSEC",
      isSortable: true,
      key: "sortableDnssec",
      accessor: (row) => {
        const possibleDnsSecValues = [
          "Signed",
          "signedDelegation",
          "Enabled",
        ].map((value) => value.toLowerCase());
        const isIndicated = possibleDnsSecValues.includes(
          row?.dnssec?.toLowerCase()
        );
        return (
          <div>
            {row.dnssec ? (
              <>
                <div
                  className={` ${
                    isIndicated ? "no-pointer" : "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (!isIndicated) {
                      SecurityIssue(row.host);
                    }
                  }}
                >
                  <DecisionToggle
                    label={
                      row.dnssec?.charAt(0)?.toUpperCase() +
                      row.dnssec?.slice(1)
                    }
                    isIndication={
                      possibleDnsSecValues?.includes(row?.dnssec?.toLowerCase())
                        ? true
                        : false
                    }
                  />
                </div>
              </>
            ) : (
              <>-</>
            )}
          </div>
        );
      },
    },
    {
      Header: "Name Servers",
      isSortable: true,
      key: "sortableNameServers",
      accessor: (row) => (
        <span>
          {row?.nameservers && row.nameservers.length > 0
            ? row.nameservers.map((el, index) => (
                <Badge key={index} className="secondary-badges rounded">
                  {el}
                </Badge>
              ))
            : "-"}
        </span>
      ),
    },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.first_detected
              ? moment(row?.first_detected).format("DD/MM/YY")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.change_status != null ? (
              <CurrentStatus
                status={row?.change_status}
                tooltipInfo={row?.changes}
                headerKeys={{
                  host: "Domain Name",
                  registrar: "Registrar",
                  registration_date: "Registered On",
                  validity: "Expires On",
                  sortableDnssec: "DNSSEC",
                  sortableNameServers: "Name Servers",
                  first_detected: "First Detected",
                  change_status: "Current State",
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const updateTabsWithData = (dnsData) => {
    const hostName = Array.from(new Set(dnsData.map((el) => el.host))).map(
      (host, index) => ({
        id: index,
        name: host,
        type: "Domain Name: " + host,
        key: "1",
        active: false,
      })
    );

    const registrarName = Array.from(
      new Set(dnsData.map((el) => el.registrar))
    ).map((name, index) => ({
      id: index,
      name: name ? name : "-",
      type: name ? "Registrar: " + name : "Registrar: -",
      key: "2",
      active: false,
    }));

    let nameServersData = [];
    dnsData.map((el) => {
      el.nameservers?.map((ol) => {
        nameServersData?.push(ol);
      });
    });

    const nameServers = Array.from(new Set(nameServersData)).map(
      (name, index) => ({
        id: index,
        name: name,
        type: "Name Server: " + name,
        key: "3",
        active: false,
      })
    );

    setTabs([
      {
        eventKey: "1",
        title: "Domain Name",
        contentTitle: "Domain Name",
        data: Array.from(hostName),
      },
      {
        eventKey: "2",
        title: "Registrar",
        contentTitle: "Registrar",
        data: Array.from(registrarName),
      },
      {
        eventKey: "3",
        title: "Name Servers",
        contentTitle: "Name Servers",
        data: Array.from(nameServers),
      },
      {
        eventKey: "4",
        title: "DNSSEC",
        contentTitle: "DNSSEC",
        data: Array.from(dnsFilterOption),
      },
    ]);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const fetchIntervalRef = useRef();

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleRowSelect = (selectedRows) => {};

  useEffect(() => {
    clearInterval(fetchIntervalRef.current);
    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanningStatus === 3 || scanningStatus === -1 || scanningStatus === 4) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchDns();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchDns();
        fetchIntervalRef.current = setInterval(fetchDns, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredDns = dns.filter((item) => {
    // Check if item matches all groups of filters (intersection)
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      // Check if item matches at least one filter in the current group (union)
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return item.host?.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return item.registrar?.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "3") {
          return item.nameservers?.some(
            (ns) => ns.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "4") {
          return (
            (filter.name === "Signed" &&
              item.dnssec?.toLowerCase() === "signed") ||
            (filter.name === "Unsigned" &&
              item.dnssec?.toLowerCase() === "unsigned")
          );
        } else if (eventKey === "6") {
          return item?.change_status === statusMap[filter.name.toLowerCase()];
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      item.host?.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.registrar?.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.nameservers?.some((ns) =>
        ns.toLowerCase().includes(searchValue.toLowerCase())
      );

    return matchesFilters && matchesSearch;
  });

  const handleBarChartClick = (dns) => {
    const dnssecStatus = dns.includes("Signed") ? "Signed" : "Unsigned";
    const updatedFilters = webUpdatesFilter(
      dnssecStatus,
      activeFilters,
      "4",
      "DNSSEC"
    );

    const updatedDnssecFilters = tabs[3].data.map((dnssec) => ({
      ...dnssec,
      active: updatedFilters.some((filter) => filter.name === dnssec.name),
    }));
    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[3] = {
        ...newTabs[3],
        data: updatedDnssecFilters,
      };
      return newTabs;
    });
    setActiveFilters(updatedFilters);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      isDatainProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing && noDataDoughnutChart ? (
                        <NoData />
                      ) : noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : (
                        <DoughnutChart
                          data={doughnutChartData}
                          options={doughnutChartOptions}
                          onHandleClick={handleBarChartClick}
                        />
                      )
                    }
                    title={"DNSSEC"}
                    isCentered={false}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-12">
                      <GenericCard
                        children={
                          <WebUpdatesHorizontal
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDatainProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"DNS Scanner"} />
            </div>
          </div>

          <div className="pb-5">
            {/* <div className="table-container">
              <div className="table-left">Results</div>
              <div>
                <BaseInput
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  icon={Search}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div>
              <BaseFilter
                tabs={tabs}
                onFilterChangeWaf={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                iskeyfilter={true}
                totalRecords={filteredDns?.length}
                exportHeader={[
                  "Domain",
                  "Registrar",
                  "Registered On",
                  "Expired On",
                  "DNSSEC",
                  "Name Server",
                ]}
                exportTitle={`DNS_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportRows={filteredDns.map((el) => {
                  let r_date = el.registration_date;
                  let e_date = el.validity;
                  return [
                    el.host,
                    el.registrar,
                    r_date,
                    e_date,
                    el.dnssec?.charAt(0)?.toUpperCase() + el.dnssec?.slice(1),
                    el.nameservers && el?.nameservers?.length > 0
                      ? `"${el?.nameservers?.join(", ")}"`
                      : "",
                  ];
                })}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredDns}
                selectable={true}
                onRowSelect={handleRowSelect}
                showCheckboxes={false}
                action={false}
                loading={isLoading}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Dns;
